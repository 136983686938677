.flex {
  display: flex;
}

.flex-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
}

.vh100 {
  max-height: 100vh;
}

.hidden {
  display: hidden;
}

p {
  margin: 0;
}

.title {
  text-transform: capitalize;
}

.font-large {
  font-size: 1.5rem;
}

.font-medium {
  font-size: 1rem;
}

.font-small {
  font-size: 0.5rem;
}

.font-black {
  color: black;
}

.nowrap {
  white-space: nowrap;
}

.editable {
  cursor: pointer;
  background-color: #dfd1f1 !important;
  /* TODO: don't use important */
}

.readOnly {
  cursor: default;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.pos-relative {
  position: relative;
}

.inline {
  display: inline;
}

.full-width {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flex-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.stat-display-container {
  width: 150px;
}

.stat-display {
  width: 100%;
}

.padding-small {
  padding: 0.5rem;
}

.padding-medium {
  padding: 1rem;
}

.padding-large {
  padding: 1.5rem;
}

.margin-small {
  margin: 0.5rem;
}

.margin-medium {
  margin: 1rem;
}

.margin-large {
  margin: 1.5rem;
}

.rounded-border-box {
  border-style: solid;
  border-color: black;
  border-width: 2px;
  border-radius: 20px;
}

.display-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  margin: 0.5rem;
}

.display-text {
  white-space: nowrap;
  margin: 0;
}

.display-value {
  height: 100%;
  border-radius: 15%;
  text-align: center;
  background-color: #e3e3e3;
  &.tiny {
    font-size: 1rem;
  }
  &.small {
    width: 30%;
    font-size: 1.5rem;
  }
  &.large {
    width: 70%;
    font-size: 2rem;
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
  color: #121212;
}
textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
  &:disabled {
    color: grey;
  }
}
button {
  color: #3992ff;
  font-weight: 500;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

#root {
  width: 100vw;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.popover-container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(0%, -90%);
  width: auto;
  background: yellow;
}

.modal-container {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  background-color: #fefefe;
  margin-top: 15%;
  padding: 20px;
  border: 1px solid #888;
  min-width: 30%;
  max-width: 70%;
  overflow: scroll;
  max-height: 70%;
}

#nav {
  width: 100%;
  padding: 8px;
  border-bottom: 2px solid grey;
  background-color: white;
  z-index: 2;
  height: 80px;
}

#sidebar {
  border-right: 2px solid grey;
  min-height: 100%;
}

#detail {
  width: 100vw;
  overflow: scroll;
  scrollbar-width: 10px;
  height: calc(100vh - 80px);
}

.character-list {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

#main-nav {
  display: flex;
  align-items: center;
  max-width: calc(100vw - 88px);
  max-height: 64px;
}

.sharing-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#save-container {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 100%;
  max-height: 64px;
  width: 72px;
  display: flex;
  align-items: center;
}

.character-sheet-container {
  width: fit-content;
}
